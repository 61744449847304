<template>
  <v-sheet v-bind="propsCompSheet" data-cy="documents-create">
    <template v-if="folderId">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <div class="formField">
            <span class="reqMark">*</span>
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.name')"
              v-model="editName"
              :error-messages="editNameErrors"
              @input="$v.editName.$touch()"
              @blur="$v.editName.$touch()"
              type="text"
            >
            </v-text-field>
          </div>
          <div class="formField">
            <!-- <span class="reqMark">*</span> -->
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('common.description')"
              v-model="editDescription"
              :error-messages="editDescriptionErrors"
              @input="$v.editDescription.$touch()"
              @blur="$v.editDescription.$touch()"
              type="text"
            >
            </v-text-field>
          </div>

          <div class="formField">
            <span class="reqMark noSpace">*</span>
            <v-radio-group
              :label="$t('documents.fileClass')"
              mandatory
              v-model="editFileClass"
              @input="$v.editFileClass.$touch()"
              @blur="$v.editFileClass.$touch()"
            >
              <v-radio
                :label="$t('documents.fileClassNormal')"
                value="normal"
              ></v-radio>
              <v-radio
                :label="$t('documents.fileClassHtml')"
                value="html"
              ></v-radio>
              <v-radio
                :label="$t('documents.fileClassLink')"
                value="link"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="formField">
            <span v-if="fileRequired" class="reqMark">*</span>
            <v-file-input
              v-bind="propsFormFields"
              :label="$t('documents.formFileUpload')"
              show-size
              v-model="editUploads"
            ></v-file-input>
          </div>
          <div v-if="editFileClass == 'link'" class="formField">
            <span v-if="linkAddressRequired" class="reqMark">*</span>
            <v-text-field
              v-bind="propsFormFields"
              :label="$t('documents.linkAddress')"
              v-model="editLinkAddress"
              :error-messages="editLinkAddressErrors"
              @input="$v.editLinkAddress.$touch()"
              @blur="$v.editLinkAddress.$touch()"
              type="text"
            ></v-text-field>
          </div>

          <div class="formField">
            <v-checkbox
              v-bind="propsFormFields"
              :label="$t('documents.private')"
              v-model="editPrivate"
            ></v-checkbox>
          </div>
          <div class="formField">
            <v-autocomplete
              v-bind="propsFormFields"
              v-model="editOwnerId"
              :items="users"
              item-value="id"
              :label="$t('users.owner')"
            >
              <template v-slot:selection="{ item }">
                <span>{{ item.name }} {{ item.surnames }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    >{{ item.name }} {{ item.surnames }}</v-list-item-title
                  >
                  <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </v-form>
      </div>
      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <v-btn
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpload"
          data-cy="button-upload"
          >{{ $t("common.upload") }}</v-btn
        >
      </div>
    </template>
    <v-alert v-else type="error">
      {{ $t("documents.error") }}
    </v-alert>
  </v-sheet>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import { required, maxLength, url, requiredIf } from "vuelidate/lib/validators";

const optionalUrl = (value, vm) => {
  if (!vm.linkAddressRequired) {
    return true;
  }
  return url(value);
};

export default {
  name: "DocumentCreate",
  mixins: [ComponentStatus],
  props: {
    folderId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,

    editName: "",
    editDescription: "",
    editFileClass: "normal",
    editLinkAddress: "https://",
    editPrivate: false,
    editOwnerId: null,
    editUploads: [],
  }),
  validations: {
    editName: {
      required,
      maxLength: maxLength(1000),
    },
    editDescription: {},
    editUploads: {
      required: requiredIf("fileRequired"),
    },
    editLinkAddress: {
      required: requiredIf("linkAddressRequired"),
      url: optionalUrl,
    },
    editFileClass: { required },
    editPrivate: {},
  },
  computed: {
    fileRequired() {
      if (this.editFileClass !== "link") {
        return true;
      }
      return false;
    },
    linkAddressRequired() {
      if (this.editFileClass === "link") {
        return true;
      }
      return false;
    },
    editUserId() {
      return this.$store.getters["auth/getUserId"];
    },
    users() {
      return this.$store.getters["users/readAll"];
    },
    folder() {
      return this.$store.getters["folders/readById"](this.folderId);
    },
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      // if (this.firstFormClick) {
      //   return true;
      // }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    editNameErrors() {
      const errors = [];
      if (!this.$v.editName.$dirty) return errors;
      if (!this.$v.editName.required) {
        errors.push(this.$t("common.errors.required"));
      }
      return errors;
    },
    editDescriptionErrors() {
      const errors = [];
      if (!this.$v.editDescription.$dirty) return errors;
      return errors;
    },
    editFileClassErrors() {
      const errors = [];
      if (!this.$v.editFileClass.$dirty) return errors;
      if (!this.$v.editFileClass.required) {
        errors.push(this.$t("common.errors.required"));
      }
      return errors;
    },
    editLinkAddressErrors() {
      const errors = [];
      if (!this.$v.editLinkAddress.$dirty) return errors;
      if (!this.$v.editLinkAddress.required) {
        errors.push(this.$t("common.errors.required"));
      }
      if (!this.$v.editLinkAddress.url) {
        errors.push(this.$t("common.errors.url"));
      }
      return errors;
    },
  },
  watch: {
    // editUserId: {
    //   immediate: true,
    //   handler: function (newValue) {
    //     if (newValue && this.editOwnerId === null) {
    //       this.editOwnerId = newValue;
    //     }
    //   },
    // },
    folderId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.$store
            .dispatch("folders/readById", this.folderId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    folder: {
      immediate: true,
      handler: function (newValue /* , oldValue */) {
        this.editPrivate = newValue.private;
        this.editOwnerId = newValue.ownerId;
      },
    },
  },
  created() {
    this.$store.dispatch("users/readAll").then((/* result */) => {});
  },
  methods: {
    clickUpload() {
      this.setStatusCreating();
      const reader = new FileReader();
      reader.onload = (event) => {
        let file = event.target.result;

        let payload = {
          name: this.editName,
          fileName: this.editUploads.name,
          fileType: this.editUploads.type,
          fileClass: this.editFileClass,
          file: file,
          description: this.editDescription,
          private: this.editPrivate,
          folderId: this.folderId,
          ownerId: this.editOwnerId,
        };
        if (
          this.editFileClass === "link" &&
          this.editLinkAddress !== "https://"
        ) {
          payload.fileName = "link.txt";
          payload.fileType = "text/plain";
        }
        this.$store
          .dispatch("documents/create", payload)
          .then((result) => {
            // Removing all errors from server.
            this.$v.$touch();
            // Setting component status to success.
            this.setStatusCreateSuccess();
            this.$store.commit("status/showSuccess");
            if (result) {
              this.$router.push({
                name: "documents-show",
                params: {
                  id: result,
                },
              });
            } else {
              this.$router.push({
                name: "documents-index",
              });
            }
          })
          .catch((error) => {
            let errorMessage = "";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              let errorToken = error.response.data.message.match(/^(.+?):/);
              if (errorToken) {
                errorToken = errorToken[1];
                errorMessage = this.$t(`serverMessages.${errorToken}`);
              }
            }
            // Setting the appropriate error markers from the server response.
            this.$v.$touch();
            // Setting component status to failure.
            this.setStatusCreateError();
            this.$store.commit("status/showError", errorMessage);
          });
      };
      if (
        this.editFileClass === "link" &&
        this.editLinkAddress !== "https://"
      ) {
        const tempFile = new File([this.editLinkAddress], "link.txt", {
          type: "text/plain",
        });
        reader.readAsDataURL(tempFile);
      } else {
        reader.readAsDataURL(this.editUploads);
      }
    },
  },
};
</script>
